import React from 'react';

import {
	ItemsProviderContext,
	useItemsProviderContext,
	WithKey,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';

interface IProps<T extends WithKey> {
	title: React.ReactNode;
	inner?: (context: ItemsProviderContext<T>) => JSX.Element;
	withoutItemsCount?: boolean;
	className?: string;
}

const TableTitle: <T extends WithKey>(p: IProps<T>) => React.ReactElement<T> = <T extends WithKey, >({
	title,
	inner,
	withoutItemsCount = false,
	className = 'site-headline site-headline_with-button clearfix',
}: IProps<T>) => {
	const context = useItemsProviderContext<T>();

	if (!context.actions) throw new Error('Need ItemsProvider context!');

	const { state: { pagination } } = context;

	return (
		<div className={className}>
			<span><h1 className="pull-left">{title}</h1></span>
			{!withoutItemsCount && pagination ? (
				<h1 className="pull-left">
					: #
					{pagination.total}
				</h1>
			) : ''}
			{inner && inner(context)}
		</div>
	);
};

export default TableTitle;
