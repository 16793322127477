import React from 'react';

import '@commonTuna/react/scss/components/rangepickerWithAdaptiveTooltipLoader.scss';

const RangepickerWithAdaptiveTooltipLoader: React.FC = () => {
	return <div className="datepicker-component datepicker-component-loader">
		<div className="ant-picker ant-picker-range">
			Loading...
		</div>
	</div>;
};

export default RangepickerWithAdaptiveTooltipLoader;
