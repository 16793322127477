import React from 'react';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import { EditableTableProps } from '@common/react/components/Core/EditableTable/EditableTable';
import { WithKey } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

const EditableTable = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EditableTable" */
		'@common/react/components/Core/EditableTable/EditableTable'
	) as any), {
	fallback: <Loader />,
	resolveComponent: (value) => (value as any).EditableTable,
});

const LazyEditableTable: <T extends WithKey>(p: EditableTableProps<T>) => React.ReactElement<T> = <T extends WithKey, >(
	props,
) => {
	return <EditableTable
		{...props}
	/>;
};

export default LazyEditableTable;
