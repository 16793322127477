import * as React from 'react';

import { BaseParams } from '@common/typescript/objects/BaseParams';

import FiltersComponent, { IProps as FilterComponentProps } from '@common/react/components/UI/FiltersComponent/FiltersComponent';

interface IProps<T> {
	additionalParams: T;
	tooltipValues: JSX.Element;
	apply?: boolean;
	filterClean?: (name, value) => void;
	filtersClean?: (filterNames?: BaseParams) => void;
	disabled?: boolean;
	popoverStyle?: React.CSSProperties;
	buttonClassName?: string;
	popoverClassName?: string;
	getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
	triggerNode?: React.ReactNode;
	filtersHandler?: {[key: string]: (value: any) => boolean};
	title?: string;
	customNode?: React.FC<FilterComponentProps>;
	getChildrenList?: (tooltipValues: JSX.Element) => any;
	open?: boolean;
	onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
	placement?: string | null;
	onApply?: (e) => void;
}

const getFilterHandler = (value: any, key) :() => boolean => {
	if (value === undefined || value === null || value === '') {
		return () => false;
	}
	if (key === 'timeInterval' || key === 'isActive') {
		return () => value > 0;
	}
	if (typeof value === 'boolean') {
		return () => true;
	}
	if (typeof value === 'number') {
		return (): boolean => value > -1;
	}
	if (typeof value === 'string') {
		if (!isNaN(+value)) {
			return (): boolean => +value > -1;
		}
		return () => true;
	}
	if (Array.isArray(value)) {
		return (): boolean => value.length > 0;
	}
	return () => false;
};

const getFilterClean = (filterClean, value: any, key): () => void => {
	// todo need fix: looks strange common code doesn't know anything about 'timeInterval' or 'isActive'
	if (value === undefined || value === null || value === '') {
		return () => undefined;
	}
	if (key === 'timeInterval' || key === 'isActive') {
		return () => filterClean(key, 0);
	}
	if (key === 'status') {
		return () => filterClean(key, undefined);
	}
	if (key === 'gender') {
		return () => filterClean(key, '');
	}
	if (typeof value === 'boolean') {
		return () => filterClean(key, null);
	}
	if (typeof value === 'number') {
		return (): boolean => filterClean(key, -1);
	}
	if (typeof value === 'string') {
		if (!isNaN(+value)) {
			return (): boolean => filterClean(key, -1);
		}
		return () => filterClean(key, '');
	}
	if (Array.isArray(value)) {
		return (): boolean => filterClean(key, []);
	}
	return () => undefined;
};

export const getFilters = (params, node, options) => {
	const { filtersHandler = {} } = options;
	const filters: Array<() => boolean> = [];
	return [...React.Children.map(
		node,
		(child) => {
			const props = child?.props;
			if (typeof props === 'undefined') {
				return () => false;
			}

			if (Array.isArray(props['data-param'])) {
				props['data-param'].slice(1).forEach((value) => {
					filters.push(filtersHandler[value] ? () => filtersHandler[value](params[value])
						: getFilterHandler(params[value], value));
				});
				const key = props['data-param']?.[0]; const
					value = params[key];
				return filtersHandler[key] ? () => filtersHandler[key](value) : getFilterHandler(value, key);
			}
			const key = props['data-param'] ? props['data-param'] : props.param;
			const value = params[key];

			return filtersHandler[key] ? () => filtersHandler[key](value) : getFilterHandler(value, key);
		},
	), ...filters];
};

export const getClean = (params, node, { filterClean, filtersClean }) => {
	return () => {
		if (filterClean) {
			React.Children.map(
				node,
				(filter) => {
					const props = filter?.props;
					if (typeof props === 'undefined') {
						return () => undefined;
					}

					if (Array.isArray(props['data-param'])) {
						props['data-param'].forEach((value) => {
							getFilterClean(filterClean, params[value], value)();
						});
					}
					const key = props['data-param'] ? props['data-param'] : props.param;
					const value = params[key];
					getFilterClean(filterClean, value, key)();
				},
			);
		}
		if (filtersClean) {
			const clearFilters: BaseParams = {};

			React.Children.map(node, (filter) => {
				const props = filter?.props;
				if (props && (props['data-param'] || props.param)) {
					if (Array.isArray(props['data-param'] || props.param)) {
						(props['data-param'] || props.param).forEach((param) => clearFilters[param] = undefined);
					} else {
						clearFilters[props['data-param'] || props.param] = undefined;
					}
				}
			});

			filtersClean(clearFilters);
		}
	};
};

export const FilterNode: React.FC<{filters, children: any, filtersHandler}> = ({ filters, children, filtersHandler = {} }) => {
	const param = typeof children === 'object' ? children?.props?.['data-param'] || children?.props?.param : '';
	const notDefault = Array.isArray(param)
		? param.some((item) => (filtersHandler[item] || getFilterHandler(filters?.[item], item))(filters?.[item]))
		: (filtersHandler[param] || getFilterHandler(filters?.[param], param))(filters?.[param]);

	return <>
		{param && notDefault && <span className="selected-filter" />}
		{children}
	</>;
};

const defaultGetChildrenList = (tooltipValues) => tooltipValues?.props?.children;

const FilterPropsHandler = <T extends object>({
	tooltipValues,
	additionalParams,
	apply,
	filterClean,
	filtersClean,
	disabled = false,
	filtersHandler = {},
	getChildrenList = defaultGetChildrenList,
	customNode: CustomNode = FiltersComponent,
	placement,
	...props
}: IProps<T>) => {
	const filters: Array<() => boolean> = getFilters(additionalParams, getChildrenList(tooltipValues), { filtersHandler });

	const clean = React.useMemo(() => {
		return getClean(additionalParams, getChildrenList(tooltipValues), { filterClean, filtersClean });
	}, [filterClean, tooltipValues]);

	return (
		<CustomNode
			apply={apply}
			tooltipValues={defaultGetChildrenList === getChildrenList
				? React.cloneElement(tooltipValues, {
					...tooltipValues?.props,
					children: React.Children.map(
						tooltipValues?.props?.children,
						(child) => <FilterNode
							{...(typeof child === 'object' ? child?.props : {})}
							filtersHandler={filtersHandler}
							filters={additionalParams}
						>
							{child}
						</FilterNode>,
					),
				})
				: tooltipValues}
			filters={filters}
			filtersClean={clean}
			disabled={disabled}
			placement={placement as any}
			{...props}
		/>
	);
};

export default FilterPropsHandler;
